.no-scrollbar::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@keyframes scaleLoop {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.animate-scaleLoop {
  animation: scaleLoop 2s infinite ease-in-out;
}
